import plugin from 'tailwindcss/plugin';
import {CSSRuleObject} from 'tailwindcss/types/config';
import * as designTokens from './design-tokens';
import {filterTokens, formatTokens} from './util';

/**
 * A plugin that adds font components to the CSS using design tokens.
 * This allows us to use design token fonts as Tailwind component classes.
 *
 * @param addComponents - The function to add components to the CSS.
 */
export const fontComponentsPlugin = plugin(function ({addComponents}) {
  // Filter and format font tokens from design tokens module
  const fontTokens = formatTokens(
    filterTokens(designTokens, isFontToken)
  ) as Record<string, FontToken>;

  //   Convert design tokens into viable CSS rules
  const fontComponents = Object.fromEntries(
    Object.entries(fontTokens).map(([key, token]) => [
      formatFontKey(key),
      createFontComponent(token),
    ])
  ) as CSSRuleObject;

  addComponents(fontComponents);
});

/**
 * Checks if a given key is a font token.
 * @param key - The key to check.
 * @returns A boolean indicating whether the key is a font token.
 */
function isFontToken(key: string) {
  return key.toLowerCase().startsWith('font');
}

function formatFontKey(key: string) {
  const isBodyFont = key.startsWith('font-body');
  const formattedKey = isBodyFont ? formatBodyFontKey(key) : key;
  return `.${formattedKey}`;
}

/**
 * Formats the key for the body font.
 * This makes the key more readable and usable as a CSS class.
 *
 * @param key - The key to be formatted.
 * @returns The formatted body font key.
 */
function formatBodyFontKey(key: string) {
  const parts = key.split('-');
  const number = parts[2].charAt(0);
  const isBold = parts[3] === 'm';
  return `font-body-${number}${isBold ? '-bold' : ''}`;
}

/**
 * Converts a font design token into a CSS Rules object.
 * Converts the font size from pixels to rem.
 * Appends appropriate fallback font families to the font family.
 *
 * @param token - The font token to create the component from.
 * @returns The font component with updated properties.
 */
function createFontComponent(token: FontToken) {
  // Remove properties that are not needed for font components
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {paragraphIndent, paragraphSpacing, textCase, textDecoration, ...font} =
    token;

  // Convert token properties to usable CSS values
  return {
    ...font,
    fontSize: toRem(parseInt(token.fontSize.toString(), 10)),
    fontFamily: `"${token.fontFamily}", BRSonoma, BRSonomaCF, Arial, Helvetica, monospace`,
    lineHeight:
      typeof token.lineHeight === 'number'
        ? toRem(token.lineHeight)
        : token.lineHeight,
  };
}

/**
 * Converts pixels to rem units.
 * Base size defaults to 16 because this is what our Figma designs use.
 * @param pixels - The number of pixels to convert.
 * @param baseSize - The base font size in pixels. Default is 16.
 * @returns The converted value in rem units.
 */
function toRem(pixels: number, baseSize = 16) {
  return `${pixels / baseSize}rem`;
}

type FontToken = {
  fontSize: number | string;
  textDecoration: string;
  fontFamily: string;
  fontWeight: number | string;
  fontStyle: string;
  fontStretch: string;
  letterSpacing: number | string;
  lineHeight: number | string;
  paragraphIndent: number | string;
  paragraphSpacing: number | string;
  textCase: string;
};
