import * as designTokens from './design-tokens';
import {filterTokens, formatTokens} from './util';

// Convert color design tokens to kebab-case tailwind theme colors
const colorTokens = formatTokens(filterTokens(designTokens, isColorToken));
export default colorTokens;

function isColorToken(key: string) {
  return key.toLowerCase().startsWith('color');
}
