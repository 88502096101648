import {getSellingPlan} from '@/selling-plan/selling-plan-store';
import {CartLineItem} from './types';

/**
 * Checks if a line item is a recurring item.
 * Gets the selling plan from the selling plan store.
 * Returns false if the selling plan expires after one charge,
 * or if there is no selling plan.
 *
 * @param lineItem - The line item to check.
 * @returns True if the line item is a recurring item, false otherwise.
 */
export function isRecurringLineItem(lineItem: CartLineItem): boolean {
  const {selling_plan_allocation} = lineItem;
  const sellingPlanId = selling_plan_allocation?.selling_plan.options[0]?.value;
  const sellingPlan = sellingPlanId ? getSellingPlan(sellingPlanId) : null;
  const isAutoExpiring =
    sellingPlan?.subscription_preferences
      ?.expire_after_specific_number_of_charges === 1;
  return !!sellingPlan && !isAutoExpiring;
}
