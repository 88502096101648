import kebabCase from 'lodash.kebabcase';

/**
 * Filters the given tokens based on the provided filter function.
 * @param tokens - The tokens to filter.
 * @param filter - The filter function that determines whether a token should be included or not.
 *                 It takes a key and value as parameters and should return a boolean.
 * @returns The filtered tokens as an object.
 */
export function filterTokens(
  tokens: Record<string, unknown>,
  filter: (key: string, value: unknown) => boolean
) {
  return Object.entries(tokens).reduce((acc, [key, value]) => {
    if (filter(key, value)) {
      return {...acc, [key]: value};
    }
    return acc;
  }, {});
}

/**
 * Formats the given tokens by converting the keys to kebab case.
 * @param tokens - The tokens to be formatted.
 * @returns The formatted tokens.
 */
export function formatTokens(tokens: Record<string, unknown>) {
  return Object.fromEntries(
    Object.entries(tokens).map(([key, value]) => [kebabCase(key), value])
  );
}
